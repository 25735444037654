import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { P } from "../components/typography/p";
import { Wrapper } from "../components/wrapper";
import { colors } from "../utilities";
import { IChildImageSharpFluid } from "../interfaces";
import { HeroBanner } from "../components/hero-banner";
import { Banner } from "../components/banner";
import { ReactComponent as Download } from "../media/images/icons/download.svg";

export interface IResourcesPage {
  data: IResourcesPageQuery;
}

const FileLink = styled.a({
  display: "block",
  fontSize: 18,
  marginBottom: 16,
  "&:hover": {
    color: colors.orange.dark,
    textDecoration: "underline",
  },
});

const PageWrapper = styled.div({
  paddingTop: 48,
  paddingBottom: 48,
});

const ResourcesPage: React.SFC<IResourcesPage> = ({ data }) => (
  <Layout>
    <SEO title={data.content.frontmatter.title} />
    <HeroBanner
      backgroundImageSrc={
        data.content.frontmatter.heroImg.childImageSharp.fluid.src
      }
      title={data.content.frontmatter.title}
    />
    <Banner />
    <Wrapper>
      <PageWrapper>
        <h3>{data.content.frontmatter.description}</h3>
        <div>
          {data.content.frontmatter.files.map((f, i) => (
            <FileLink href={f.file.publicURL} target="_blank">
              {f.title}{" "}
              <Download
                css={{ width: 18, height: 18, verticalAlign: "middle" }}
              />
            </FileLink>
          ))}
        </div>
      </PageWrapper>
    </Wrapper>
  </Layout>
);

interface IResourcesPageQuery {
  content: {
    frontmatter: {
      title: string;
      description: string;
      heroImg: IChildImageSharpFluid;
      files: {
        title: string;
        file: {
          publicURL: string;
        };
      }[];
    };
  };
}

export const query = graphql`
  query ResourcesPageQuery {
    content: markdownRemark(
      frontmatter: { templateKey: { eq: "resources-page" } }
    ) {
      frontmatter {
        title
        description
        heroImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        files {
          title
          file {
            publicURL
          }
        }
      }
    }
  }
`;

export default ResourcesPage;
